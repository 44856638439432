import React, { lazy, Suspense } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import BoxLocation from "../components/box-location";
import Speaker from "../components/speaker";
import Wall from "../components/box-wall";
import BoxEngage from "../components/box-engage";
import BoxPartecipanti from "../components/box-partecipanti";
import ReviewSlider from "../components/slider-review";
import VideoEdizione from "../components/video-edizione";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";
import BoxEdizione from "../components/box-edizione";

import ClaimTextSvg from "../icons/community-design-communication-explorer.svg";

const Spline = lazy(() => import("@splinetool/react-spline"));

const IndexPage = ({ data }) => {
  const { speakers, review } = data;

  return (
    <Layout>
      <Seo
        title="Home"
        description={`Il primo evento italiano dedicato dedicato alle intranet e alla comunicazione interna, con un focus sulla progettazione, la governance e la gestione di intranet aziendali.`}
      />

      <div className="box-intro box-intro--pb-0">
        <div className="animation-intro-wrap">
          <video
            width="1440px"
            height="720px"
            autoPlay
            muted
            loop
            playsInline
            className="animation-intro"
          >
            <source src="/assets/explore-fullhd.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center">
                <div className="box-intro__title">Intranet Italia Day</div>
                <div className="box-intro__claim">
                  <p>
                    L'evento <strong>italiano</strong> dedicato alle
                    <br />
                    <strong>Intranet</strong> e alla <strong>comunicazione interna</strong>
                  </p>
                </div>
                <div className="box-intro__date">29 maggio 2025, Milano</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-claim-text">
        <Suspense>
          <Spline
            scene="https://prod.spline.design/LH1fpiv595HjQm6T/scene.splinecode"
            className="animation-circle"
          />
        </Suspense>
        <ClaimTextSvg className="ico-svg" />
      </div>

      <BoxEngage />

      <Speaker items={speakers} />

      <Wall items={speakers} />

      <BoxLocation
        animazione="https://prod.spline.design/LH1fpiv595HjQm6T/scene.splinecode"
        locationTitle={review.locationTitle}
        locationText={review.locationText.locationText}
      />

      <BoxPartecipanti items={review.participantsText.participantsText} />

      <ReviewSlider items={review} />

      <VideoEdizione />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />

      <BoxEdizione />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    speakers: contentfulSpeakerList {
      speakers {
        name
        fullName
        surname
        role
        program
        link
        bio {
          bio
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
    review: contentfulMultiEntryPage {
      reviews {
        author
        role
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      participantsText {
        participantsText
      }
      locationTitle
      locationText {
        locationText
      }
    }
  }
`;
