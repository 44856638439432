import React from "react";
import Slider from "react-slick";

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  swipe: true,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ReviewSlider = ({ items }) => {
  return (
    <>
      <section className="section">
        <div className="box-review">
          <div className="section__bg-title text-center" aria-hidden="true">
            Review
          </div>
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <Slider {...settings} className="slider-review">
                  {items.reviews.map((item, i) => (
                    <div key={i}>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.text?.childMarkdownRemark?.html }}
                        className="box-review__text"
                      ></div>
                      {item.author && <div className="box-review__author">{item.author}</div>}
                      {item.role && <div className="box-review__role">{item.role}</div>}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewSlider;
