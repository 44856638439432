import React from "react";
import Modal from "react-overlays/Modal";
import classNames from "classnames";

import CloseIcon from "../icons/xmark-regular.svg";

// const GROUPS = ["C0003"];
// const areCookiesEnabled = () =>
//   window.OnetrustActiveGroups && GROUPS.some((item) => window.OnetrustActiveGroups.includes(item));

const renderBackdrop = (props) => <div className="backdrop" {...props} />;

const VideoOverlay = ({ show, url, isYoutube, onHide, className }) => {
  // const [showAlert, setShowAlert] = useState(false);
  // useEffect(() => {
  //   if (show && isYoutube && window.OneTrust && !areCookiesEnabled()) {
  //     setShowAlert(true);
  //     window.OneTrust.OnConsentChanged(() => {
  //       setShowAlert(!areCookiesEnabled());
  //     });
  //     return () => setShowAlert(false);
  //   }
  // }, [show, isYoutube]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="modal-label"
        className={classNames("modal", className)}
        renderBackdrop={renderBackdrop}
        aria-hidden="false"
        aria-modal="true"
      >
        <div className="modal__body">
          <button type="button" onClick={onHide} className="modal__close" aria-label="Chiudi">
            <CloseIcon className="ico-svg" />
          </button>

          <div className="iframe-responsive mb-0">
            <iframe
              title="Video Edizione precedente"
              src="https://www.youtube-nocookie.com/embed/7x1iM8aXlf8?rel=0&showinfo=0&autoplay=1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            ></iframe>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VideoOverlay;
