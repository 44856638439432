import React from "react";

const BoxPartecipanti = ({ items }) => {
  return (
    <>
      <section className="section section--grey-dark">
        <div className="box-info">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-4">
                <div className="box-info__text" dangerouslySetInnerHTML={{ __html: items }}></div>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="box-info__num">75</div>
                    <div className="box-info__label">Speaker</div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="box-info__num">350+</div>
                    <div className="box-info__label">Aziende presenti</div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="box-info__num">56</div>
                    <div className="box-info__label">Speech</div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="box-info__num">775+</div>
                    <div className="box-info__label">Partecipanti</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoxPartecipanti;
