import React, { lazy, Suspense } from "react";

import { Link } from "../components/link";
// import BoxSpeaker from "../components/box-speaker";

const Spline = lazy(() => import("@splinetool/react-spline"));

const Speaker = ({ items }) => {
  return (
    <>
      <section className="section section--grey-dark section--speaker">
        <div className="container-md">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <Suspense>
                <Spline
                  scene="https://prod.spline.design/LH1fpiv595HjQm6T/scene.splinecode"
                  className="animation-circle"
                />
              </Suspense>
              <div className="section__name">Speaker +</div>
              <div className="section__title">Share</div>
              <div className="section__subject">
                Confrontarsi e formarsi con i migliori esperti italiani e internazionali.
              </div>
              {/*
              <div className="row speakers-list">
                <div className="col-6 col-md-4">
                  {items.speakers.slice(0, 3).map((item, i) => (
                    <div key={i}>
                      <BoxSpeaker data={item} showProgram={false} />
                    </div>
                  ))}
                </div>
                <div className="col-6 col-md-4">
                  {items.speakers.slice(3, 6).map((item, i) => (
                    <div key={i}>
                      <BoxSpeaker data={item} showProgram={false} />
                    </div>
                  ))}
                </div>
                <div className="col-12 col-md-4">
                  <div className="row">
                    {items.speakers.slice(6, 9).map((item, i) => (
                      <div key={i} className="col-6 col-md-12">
                        <BoxSpeaker data={item} showProgram={false} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              */}
              <div className="row">
                <div className="col-12 text-center mb-5">
                  <p>
                    Gli speaker sono in via di definizione. Se vuoi proporti come relatore, per
                    condividere una storia di successo, un'innovazione o uno studio su una intranet
                    o un digital workplace, contattaci alla mail{" "}
                    <a href="mailto:info@intranetitalia.it" title="Info IID">
                      info@intranetitalia.it
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <Link to="/speaker/" className="btn-link">
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">
                        Vedi tutti gli <span className="d-block bold">speaker</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Speaker;
