import React from "react";

import { Link } from "../components/link";

const Wall = ({ title = false, items }) => {
  return (
    <>
      <section className="section section--grey-dark">
        <div className="container-md">
          {title && (
            <div className="row pt-5">
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-end">
                  <div className="section__title mb-0">
                    <span>Our</span> speakers
                  </div>
                  <Link to="/speaker/" className="btn-link btn-link--small mb-4">
                    <span className="btn-link__circle"></span>
                    <span className="btn-link__text">
                      Tutti gli <span className="d-block bold">speaker</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="box-wall">
                {items.speakers?.map((item, i, res) => (
                  <span key={i}>
                    <Link
                      to={`/speaker/${item.link}/`}
                      title={`Visualizza il profilo di ${item.fullName}`}
                      className="box-wall__link"
                    >
                      {item.fullName}
                    </Link>
                    <span>{i < res.length - 1 ? " / " : <></>}</span>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wall;
