import React from "react";

import { Link } from "./link";

const BoxEdizione = () => {
  return (
    <>
      <section className="section">
        <div className="box-edizione text-center">
          <div className="box-edizione__text text-nowrap">IID intranet italia Day2025 edizione</div>
          <Link
            to="/edizioni-passate/"
            title="Edizioni passate"
            className="box-edizione__link text-uppercase"
          >
            scopri le scorse edizioni +
          </Link>
        </div>
      </section>
    </>
  );
};

export default BoxEdizione;
